function coolFunc(longParamName) {
 //console.log("I am main.js: "+ longParamName);
}
//coolFunc('this is my message');

// remap jQuery to $
(function($){

  // Combines the autoHeight and matchHeight functions into
  // one call for initial and resize window events.
  function fillInTheHeight() {
    if(window.innerWidth > 960){
      $('.blog-posts .grid-row .col-1-2').matchHeight();
      $('.blog-gallery > .grid-row > .col-1-2').matchHeight();
      $('.social-stripe > .grid-row > .col-1-2').matchHeight();
      $('.blog-index > .grid-row > .col-1-2').matchHeight();
      $('.gallery-content > .grid-row > .col-1-2').matchHeight();
      $('.content_50_50 .grid-row > .col-1-2').matchHeight();
      $('.category-group .category-item').matchHeight();
    }
  }

  /* trigger when page is ready */
  $(document).ready(function (){

    fillInTheHeight();

  });

  $(window).resize(function() {

    fillInTheHeight();

  });

  /* optional triggers
  $(window).load(function() {

  });
  */

})(window.jQuery);

// Fix for !important flag being improperly applied to inlined CSS
// background-image values
window.addEventListener('load', function() {
  var lazyBackgroundElements = [].slice.call(document.querySelectorAll('.lazyloaded'));
  if (lazyBackgroundElements.length < 1) {
    return;
  }
  lazyBackgroundElements.forEach(function(el){
    el.style.cssText = el.style.cssText.replace(');', ') !important;');
  });
});
