(function($){
  $(document).ready(function (){

    // initialize input widgets first
    var timePickerAppend = $('.consultation-form');
    $('.time-field input').timepicker({
      'showDuration': true,
      'timeFormat': 'g:ia',
      'appendTo' : timePickerAppend
    });
    $('.date-field input').datepicker({
      'format': 'm/d/yyyy',
      'autoclose': true,
    });

    var leftSpacing = $('#consultation-date-1').outerWidth();
    console.log(leftSpacing);
    $('.ui-timepicker-wrapper').css('margin-left', '-'+leftSpacing+"px");

    $('.field').has('#consultation-date-'+x+':focus').on('click', function(e){
      $('.field').removeClass('focus');
      $(this).addClass('focus');
    });

    // add/remove Date and Time input fields
    var max_fields = 7; //maximum input boxes allowed
    var wrapper = $(".additional-fields"); //Fields wrapper
    var add_button = $("#duplicateDateTime"); //Add button ID
    var remove_button = $("#removeDateTime"); //Remove button ID
    var x = 1; //initlal text box count
    $(add_button).click(function(e){ //on add input button click
      e.preventDefault();
      if(x < max_fields){ //max input box allowed
        x++; //text box increment
        $(wrapper).append('<div class="field date-field">' +'<label for="consultation-date-'+x+'" class="default">Date</label>' +'<input type="text" id="consultation-date-'+x+'" name="contact_date_'+x+'" class="contact-date" required="required" data-ga-event="Consultation Form OnPage, Date Field" placeholder="MM/DD/YEAR">' +'</div>'); 
        $(wrapper).append('<div class="field time-field">' +'<label for="consultation-time-'+x+'" class="default">Time</label>' +'<input type="text" id="consultation-time-'+x+'" name="contact_time_'+x+'" class="contact-time" required="required" data-ga-event="Consultation Form OnPage, Time Field" placeholder="9:00 AM">' +'</div>');
        $('.additional-fields #consultation-time-'+x+'').timepicker({
          'showDuration': true,
          'timeFormat': 'g:ia',
          'appendTo' : timePickerAppend
        });
        $('.additional-fields #consultation-date-'+x+'').datepicker({
          'format': 'm/d/yyyy',
          'autoclose': true
        });
        // console.log(x);
      }
    });
    $(remove_button).click(function(e){ //on add input button click
      e.preventDefault();
      if(x < max_fields && x > 1){ //max input box allowed
        $('.field').has('#consultation-date-'+x).remove();
        $('.field').has('#consultation-time-'+x).remove();
        x--;
      };
      // console.log(x);
    });

  });
})(window.jQuery);