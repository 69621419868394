function overlaySwitch(open, overlay, close) {
  var urlPath = window.location.hash;
  var pageURL = document.location.href
  $(open).on( 'click', function(event) {
    event.preventDefault();
    scroll = $(window).scrollTop();
    $('#layout').toggleClass('noscroll');
    $(overlay).fadeIn("fast");
    window.history.pushState('forward', null, urlPath+overlay);
  });
  $(close).on( 'click', function(event) {
    event.preventDefault();
    $('#layout').toggleClass('noscroll');
    $(window).scrollTop(scroll);
    $(overlay).fadeToggle("fast");
    history.back();
  });
  $(window).on('popstate', function () {
    $(overlay).fadeOut('fast');
    $('#layout').removeClass('noscroll');
    $(window).scrollTop(scroll);
  });
  $(document).keyup(function(e) {
    e.preventDefault();
    if (e.keyCode == 27) {
      $('#layout').removeClass('noscroll');
      $(overlay).fadeOut('fast');
      $(window).scrollTop(scroll);
      window.history.replaceState('', 'Title', pageURL);
    }
  });
}

/* trigger when page is ready */
$(document).ready(function (){

  // PPC Overlay
  overlaySwitch('#openPpcOverlay', '#ppcContactOverlay', '#closePpcContactOverlay');

  // Blog Archives
  overlaySwitch('#openPostArchiveOverlay', '#postArchiveOverlay', '#closePostArchiveOverlay');

  // Blog Tags
  overlaySwitch('#openPostTagOverlay', '#postTagOverlay', '#closePostTagOverlay');

  // Social Accounts
  overlaySwitch('#openSocialAccountsOverlay', '#socialAccountsOverlay', '#closeSocialAccountsOverlay');

  // Locations
  overlaySwitch('#openLocations', '#locationsOverlay', '#closeLocationsOverlay');

  // Contact Form
  overlaySwitch('#openContactForm', '#contactFormOverlay', '#closeContactFormOverlay');

  // Phone
  overlaySwitch('#openPhoneOverlay', '#phoneOverlay', '#closePhoneOverlay');

  // Menu
  overlaySwitch('#openMainNav', '#mainNavOverlay', '#closeMainNavOverlay');

  $('textarea').on('paste input', function () {
    if ($(this).outerHeight() > this.scrollHeight){
        $(this).height(1)
    }
    while ($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))){
        $(this).height($(this).height() + 1)
    }
  });

  // Menu Tabs
  $('#menuOverlay').on( 'click', function(overlay) {
    overlay.preventDefault();
    $('.overlay-menu-wrap').show();
  });
  $('#overlayMenuClose').on( 'click', function(overlay) {
    overlay.preventDefault();
    $('.overlay-menu-wrap').hide();
  });

  $('#activateViewAll').on( 'click', function(overlay) {
    overlay.preventDefault();
    $('#activatePopular').removeClass('selected');
    $('#activateRelated').removeClass('selected');
    $(this).addClass('selected');
    $('.popular-menu').hide();
    $('.related-menu').hide();
    $('.view-all-menu').fadeIn("fast");
  });

  $('#activatePopular').on( 'click', function(overlay) {
    overlay.preventDefault();
    $('#activateViewAll').removeClass('selected');
    $('#activateRelated').removeClass('selected');
    $(this).addClass('selected');
    $('.view-all-menu').hide();
    $('.related-menu').hide();
    $('.popular-menu').fadeIn("fast");
  });

  $('#activateRelated').on( 'click', function(overlay) {
    overlay.preventDefault();
    $('#activateViewAll').removeClass('selected');
    $('#activatePopular').removeClass('selected');
    $(this).addClass('selected');
    $('.view-all-menu').hide();
    $('.popular-menu').hide();
    $('.related-menu').fadeIn("fast");
  });

  $('.page-group-link').on( 'click', function(overlay) {
    overlay.preventDefault();
    $(this).parent().toggleClass("expanded");
  });

});